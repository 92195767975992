import React, { useCallback, useEffect, useState } from 'react';
import { Tag } from 'antd';
import _ from 'lodash';
import { IfilterResult, Iseacrh } from './applicantsTypes';
import moment from 'moment';

export const UseFilterTags: React.FC<IfilterResult> = ({
  filters,
  onFinish,
  form,
  setFilters,
  setOffset,
  organizations,
  setDateRadio,
  setDateRadioButton,
  setStartTime,
  setEndTime
}) => {
  const [filteredOrganization, setFilteredOrganization] = useState<
  string[] | []
  >([]);
  const closeFilter = (filter: string): void => {
    const newAs: any = _.omit(filters, [filter]);
    form.setFieldsValue({
      [filter]: undefined
    });
    onFinish(newAs);
    if (filter === 'date') {
      form?.resetFields(['start', 'from', 'to']);
      setStartTime('');
      setEndTime('');
      setDateRadio(undefined);
    }
    const isNotEmpty = Object.keys(newAs)?.some((name) => {
      if (name === 'date') {
        if (
          !(
            newAs.date.from !== undefined &&
            newAs.date.to !== undefined &&
            newAs.date.start !== undefined
          )
        ) {
          return false;
        }
      }
      if (
        newAs[name] !== undefined &&
        name !== 'limit' &&
        name !== 'offset' &&
        name !== 'search'
      ) {
        return true;
      }
      return false;
    });

    form?.setFieldValue('clearAll', isNotEmpty);
  };

  useEffect(() => {
    const tagOrganizations: string[] | [] = organizations
      ?.filter((partner) => filters?.organization?.includes(partner.id))
      .map((organization) => organization.title);
    setFilteredOrganization(tagOrganizations);
  }, [organizations, filters]);

  const resetFilters = useCallback(() => {
    form?.resetFields();
    setFilters((prevState: Iseacrh) => ({
      ...prevState,
      limit: 10,
      offset: 0,
      student: undefined,
      income: undefined,
      disability: undefined,
      gender: undefined,
      statuses: undefined,
      regions: undefined,
      age: undefined,
      organization: undefined,
      date: {
        start: undefined,
        from: undefined,
        to: undefined
      }
    }));
    form?.setFieldValue('clearAll', false);
    setOffset(0);
    setDateRadio(undefined);
    setDateRadioButton('');
    setStartTime('');
    setEndTime('');
  }, [form, filters]);

  return (
    <div>
      {Boolean(filters) && (
        <>
          {filters?.date?.from !== undefined &&
            filters?.date?.to !== undefined &&
            filters?.date?.start !== undefined && (
              <Tag onClose={() => closeFilter('date')} closable>
                {filters?.date?.start
                  ? `Start: ${moment(filters?.date?.from).format(
                      'DD/MM/YYYY'
                    )} - ${moment(filters?.date?.to).format('DD/MM/YYYY')}`
                  : `End: ${moment(filters?.date?.from).format(
                      'DD/MM/YYYY'
                    )} - ${moment(filters?.date?.to).format('DD/MM/YYYY')}`}
              </Tag>
          )}
          {filteredOrganization?.length > 0 && (
            <Tag onClose={() => closeFilter('organization')} closable>
              Organization:{' '}
              {filteredOrganization.map((organization) => (
                <span key={organization}>{organization},</span>
              ))}
            </Tag>
          )}
          {filters?.age !== undefined && (
            <Tag onClose={() => closeFilter('age')} closable>
              {`Age: ${filters?.age?.from} - ${filters?.age?.to}`}
            </Tag>
          )}
          {filters?.gender !== undefined && (
            <Tag onClose={() => closeFilter('gender')} closable>
              {`Gender: ${filters?.gender}`}
            </Tag>
          )}
          {filters?.student !== undefined && (
            <Tag onClose={() => closeFilter('student')} closable>
              {`Student: ${filters?.student ? 'yes' : 'no'}`}
            </Tag>
          )}
          {filters?.statuses !== undefined && (
            <Tag onClose={() => closeFilter('statuses')} closable>
              {`Statuses: ${filters?.statuses}`}
            </Tag>
          )}
          {filters?.income !== undefined && (
            <Tag onClose={() => closeFilter('income')} closable>
              {`Paid job: ${filters?.income ? 'yes' : 'no'}`}
            </Tag>
          )}
          {filters.disability !== undefined && (
            <Tag onClose={() => closeFilter('disability')} closable>
              {filters.disability === 'yes'
                ? 'Disability: Disability'
                : filters.disability === 'vul'
                  ? 'Vulnerability: Vulnerability'
                  : 'No Disability: No Disability'}
            </Tag>
          )}
          {filters?.regions !== undefined && (
            <Tag onClose={() => closeFilter('regions')} closable>
              {`Region: ${filters?.regions}`}
            </Tag>
          )}
          {Boolean(form?.getFieldValue('clearAll')) && (
            <Tag
              onClick={() => {
                resetFilters();
              }}
              className="clearfilter"
            >
              {'Clean All'}
            </Tag>
          )}
        </>
      )}
    </div>
  );
};
